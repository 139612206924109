import axios from 'axios'

export default {

    listTokens() {

        let path = '/api/reseller/tokens'

        return axios.get(path)
    },
    createToken(name) {

        let path = '/api/reseller/tokens'
        let data = {
            tokenName: name
        }

        return axios.post(path, data)
    },
    deleteToken(tokenId) {

        let path = '/api/reseller/tokens/' + tokenId

        return axios.delete(path)
    }
}
